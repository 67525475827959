* {
  font-family: "Nunito", sans-serif;
  --primary-color: #141414 !important;
  --secondary-color: #141414 !important;
  --thirty-color: #eeeeee !important;
  box-sizing: border-box;
}

html,
body,
#root,
#root > div {
  display: flex;
  font-family: sans-serif;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  margin: -20px;
  margin-bottom: 0;
  display: flex;
  width: calc(100% + 40px);
  padding: 30px 40px;
  background-color: #141414;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  overflow: auto;
}

.footer {
  width: calc(100% + 40px);
  padding: 30px 40px;
  margin: -20px;
  background-color: #141414;
}
